import axios from 'axios'
import dayjs from 'dayjs'
import { API_ENDPOINT, DATE_PATTERNS } from '../app/constants'

async function getAvailableAppointmentsSlots(
  date: dayjs.Dayjs,
  serviceCenterID: string,
  language: string,
  Guardian1Email: string,
) {
  // get first day of the week
  let firstDayOfWeek = date.startOf('week')
  firstDayOfWeek = firstDayOfWeek.isBefore(dayjs()) ? dayjs() : firstDayOfWeek
  // get last day of the week
  const lastDayOfWeek = firstDayOfWeek
    .add(6, 'day')
    .format(DATE_PATTERNS.iso_8601_date)
  const params = language
    ? `service_center_id=${serviceCenterID}&start_date=${firstDayOfWeek.format(
      DATE_PATTERNS.iso_8601_date,
    )}&end_date=${lastDayOfWeek}&language=${language}&email=${Guardian1Email}`
    : `service_center_id=${serviceCenterID}&start_date=${firstDayOfWeek.format(
      DATE_PATTERNS.iso_8601_date,
    )}&end_date=${lastDayOfWeek}&email=${Guardian1Email}`
  if (serviceCenterID) {
    const resp = axios.get(
      `${API_ENDPOINT}/api/get-available-appointments-slots?${params}`,
    )
    return resp
  }
  return null
}

export default getAvailableAppointmentsSlots
