import {
  Box, Grid, Typography, CircularProgress,
} from '@mui/material'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import dayjs from 'dayjs'
import ReactGA from 'react-ga4'
import { CalendarMonth, Place } from '@mui/icons-material'
import { AxiosError } from 'axios'
import PostScheduleAppointment from '../../api/postScheduleAppointment'
import {
  DATE_PATTERNS,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ERROR_MESSAGE,
} from '../../app/constants'
import ContactNumber from '../../components/ContactNumber'
import NextButton from '../../components/NextButton'
import checkRequest from '../../utils/checkRequest'
import AppBar from '../../components/AppBar'
import ViewContainer from '../../components/ViewContainer'
import BackButton from '../../components/BackButton'
import { IAppointementData } from '../../app/types'
import useDialog from '../../hooks/dialog'
import Dialog from '../../components/Dialog'

const ScheduleButton = (props: {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}) => {
  const alertHook = useDialog()
  const { isLoading, setLoading } = props
  const [progress, setProgress] = useState(0)
  const navigate = useNavigate()
  const personDetails = useSelector((state: any) => state.formInfo.PersonDetails)
  const zip = useSelector((state: any) => state.zipCode.zip)
  const guardian = useSelector((state: any) => state.formInfo.Guardian)
  const appointment = useSelector((state: any) => state.appointmentInfo)
  const rescheduleInfo = useSelector((state: any) => state.reschedule)

  let currentProgress = 0
  let isProgressComplete = false

  const data: IAppointementData = {
    PersonDetails: {
      ...personDetails,
      Reschedule: rescheduleInfo.patientNumber ? {
        ...rescheduleInfo,
        mode: true,
      } : null,
    },
    Guardian: guardian,
    Appointment: appointment,
    ZipCode: zip,
  }

  const mktCookie = getCookie('CampaignEntryURLData')
  if (mktCookie) {
    const obj = JSON.parse(mktCookie)
    data.CampaignEntryURLData = { utm_campaign: obj.utm_id }
  }

  useEffect(() => {
    if (!checkRequest(data)) {
      navigate('/')
    }
  }, [])

  const mutation = useMutation({
    mutationFn: PostScheduleAppointment(data),
    onSuccess: async (patientNumber) => {
      // Once the request is successfully completed,
      // we wait for 1.5 seconds before navigating to the confirmation page.
      setProgress(100)
      isProgressComplete = true
      await new Promise((resolve) => {
        setTimeout(resolve, 1500)
      })

      navigate('/confirm-appointment', { state: { patientNumber } })
    },
    onError: (e: AxiosError) => {
      if (e.response?.status === 409) {
        alertHook.setDialog({
          title: 'Slot unavailable',
          description: 'This slot was taken by someone else, please choose another one',
        })
        setLoading(false)
      } else {
        const errorMessage = e.response?.data ?? e.message
        navigate('/contact-form', { state: { messageState: ERROR_MESSAGE, error: `schedule-appointment: ${errorMessage}` } })
      }
    },
  })

  function getCookie(cookiename: string) {
    const cookiestring = RegExp(`${cookiename}=[^;]+`).exec(document.cookie)
    return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '')
  }

  function updateProgress() {
    if (!isProgressComplete) {
      // Generate a random number between 0.5 and 1.5 and add it to the current progress.
      currentProgress += Math.random() * (1.5 - 0.5) + 0.5

      // Updates the progress bar until it reaches 99%.
      if (currentProgress >= 99) {
        setProgress(99)
      } else {
        setProgress(Math.round(currentProgress))

        // Generate a random interval between 200 and 800.
        const randomInterval = (0.2 + Math.random() * 0.6) * 1000

        setTimeout(updateProgress, randomInterval)
      }
    }
  }

  const handleOnClick = async () => {
    ReactGA.event({
      category: 'User',
      action: 'Appointment requested',
    })

    setLoading(true)
    setProgress(0)
    currentProgress = 0
    isProgressComplete = false

    updateProgress()

    // Calls the mutation to schedule the appointment.
    mutation.mutate()
  }

  if (isLoading) {
    return (
      <>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            size={50}
            value={progress}
            variant="determinate"
          />
          <StyledProgressBox>
            <Typography variant="caption">
              {`${Math.round(progress)}%`}
            </Typography>
          </StyledProgressBox>
        </Box>
        {progress < 100 && (
          <StyledLoadingText>Scheduling an Appointment ...</StyledLoadingText>
        )}
      </>
    )
  }
  return (
    <StyledCardSection
      style={{ height: '40px', display: 'flex', marginBottom: '15px' }}
    >
      <NextButton
        onClick={handleOnClick}
        text="Schedule Now"
      />
      <Dialog
        dialogHook={alertHook}
      />
    </StyledCardSection>
  )
}

const AppointmentDetails = () => {
  const navigate = useNavigate()
  const appointmentDateTime = useSelector((state: any) => state.appointmentInfo.AppointmentDateTime)
  const startDateTime = dayjs(appointmentDateTime)
  const clinicName = useSelector((state: any) => state.serviceCenterInfo.Name)
  const clinicAddress = useSelector((state: any) => state.serviceCenterInfo.Address)
  const [isLoading, setLoading] = useState(false)

  const handleOnClickBack = () => {
    // go back to appointment selector
    navigate('/select-appointment')
  }

  return (
    <ViewContainer>
      <AppBar
        progress={4}
        title="Confirm your evaluation details"
      />
      <StyledRow
        container
        rowGap={3}
      >

        <Grid
          desktop={12}
          item
          mobile={12}
          sx={{
            justifyContent: 'center',
            display: 'flex',
          }}
          tablet={5}
        >
          <StyledBox>
            <CalendarMonth
              style={{ fontSize: '40px', color: SECONDARY_COLOR }}
            />
            <StyledCardSection>
              <StyledTitle>Evaluation Time</StyledTitle>
              <StyledDescription>
                {startDateTime.format(DATE_PATTERNS.long_date)}
                {' '}
                at
                {' '}
                {startDateTime.format(DATE_PATTERNS.time_am_pm)}
              </StyledDescription>
            </StyledCardSection>
          </StyledBox>
        </Grid>
        <Grid
          desktop={12}
          item
          mobile={12}
          sx={{
            justifyContent: 'center',
            display: 'flex',
          }}
          tablet={5}
        >
          <StyledBox>
            <Place style={{ fontSize: '40px', color: SECONDARY_COLOR }} />
            <StyledCardSection>
              <StyledTitle>Clinic Location</StyledTitle>
              <StyledDescription>
                {clinicName}
                <br />
                {clinicAddress}
              </StyledDescription>
            </StyledCardSection>
          </StyledBox>
        </Grid>
        <Grid
          desktop={1}
          item
          mobile={0}
          tablet={1}
        />
      </StyledRow>
      <ScheduleButton
        isLoading={isLoading}
        setLoading={setLoading}
      />
      {!isLoading && <BackButton onClick={handleOnClickBack} />}
      <ContactNumber />
    </ViewContainer>
  )
}

const StyledCardSection = styled(Box)`
  display: grid;
  justify-content: center;
  align-items: center;
`

const StyledRow = styled(Grid)`
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 40px;
`

const StyledTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
`

const StyledDescription = styled(Typography)`
  font-size: 14px;
`

const StyledBox = styled(Box)`
  max-width: 200px;
`

const StyledLoadingText = styled(Typography)`
  color: ${PRIMARY_COLOR};
  font-size: 16px;
  font-weight: bold;
`

const StyledProgressBox = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default AppointmentDetails
