import dayjs from 'dayjs'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import ErrorComponent from './FormErrorComponent'
import PlaceholderComponent from './FormPlaceholderComponent'
import HelperTextComponent from './FormHelperTextComponent'

const FormInputDate = ({
  control,
  errors,
  helperText,
  isLessThan20MonthsOld,
  name,
  placeholder,
  required,
}: {
  control: any;
  errors: any;
  helperText?: string | JSX.Element;
  isLessThan20MonthsOld?: boolean;
  name: string;
  placeholder: string;
  required?: boolean;
}) => {
  const [isFocus, setIsFocus] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <DatePicker
            disableFuture={isLessThan20MonthsOld}
            inputRef={ref}
            onChange={onChange}
            slotProps={{
              textField: {
                color: 'info',
                error: !!errors[name],
                helperText:
                  helperText && !errors[name] ? (
                    <HelperTextComponent helperText={helperText} />
                  ) : undefined,
                InputLabelProps: { shrink: false },
                label: !value && !isFocus && (
                  <PlaceholderComponent
                    placeholder={placeholder}
                    required={required}
                  />
                ),
                onBlur: () => setIsFocus(false),
                onFocus: () => setIsFocus(true),
                size: 'small',
              },
            }}
            value={value}
          />
        )}
        rules={{
          required: required && 'This field is required.',
          validate: (value) => {
            if (!dayjs(value).isValid()) return 'Invalid date.'
            if (isLessThan20MonthsOld) {
              const now = dayjs()
              const dateOfBirth = dayjs(value)
              const diff = now.diff(dateOfBirth, 'month')
              if (diff > 20) return 'Patient must be less than 20 months old.'
              if (now.diff(dateOfBirth) < 0) return 'Invalid date.'
            }
            return true
          },
        }}
      />
      {errors[name] && <ErrorComponent message={errors[name].message} />}
    </LocalizationProvider>
  )
}

FormInputDate.defaultProps = {
  isLessThan20MonthsOld: false,
  required: false,
}

export default FormInputDate
