import { useState } from 'react'
import { useBoolean } from 'usehooks-ts'

interface DialogContent {
  title: string
  description: string
  buttonHandler?: () => void
}

export interface DialogHook {
  setDialog: (dialogContent: DialogContent) => void
  isAlertOpen: boolean
  closeDialog: () => void
  content?: DialogContent
  setContent: (content: DialogContent) => void
  setLinkHook: (content: boolean) => void
  linkHook: boolean
}

const useDialog = (): DialogHook => {
  const modalHook = useBoolean(false)
  const [content, setContent] = useState<DialogContent | undefined>()
  const [linkHook, setLinkHook] = useState<boolean>(false)

  return {
    setDialog: (alertContent: DialogContent) => {
      setContent(alertContent)
      modalHook.setTrue()
    },
    closeDialog: () => {
      modalHook.setFalse()
      setContent(undefined)
    },
    content,
    setContent,
    isAlertOpen: modalHook.value,
    setLinkHook,
    linkHook,
  }
}

export default useDialog
