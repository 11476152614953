import axios from 'axios'

async function getPatientAppointments(data: any) {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/get-patient-appointments`

  try {
    const resp = await axios.post(URL, data)
    return resp.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default getPatientAppointments
