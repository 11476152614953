import { Button } from '@mui/material'
import styled from '@emotion/styled'
import { INFO_COLOR } from '../app/constants'
import { IBackButton } from '../app/types'

const StyledButton = styled(Button)`
  font-size: 16px;
  text-transform: none;
  width: fit-content;
  align-self: center;
  &:hover {
    background-color: transparent;
  }
`

const NextButton = ({ onClick = () => {} }: IBackButton) => (
  <StyledButton
    disableRipple
    onClick={onClick}
    style={{ color: INFO_COLOR }}
    variant="text"
  >
    Back
  </StyledButton>
)

export default NextButton
