import {
  Typography, Box, CircularProgress, Modal, TextField,
} from '@mui/material'
import styled from '@emotion/styled'
import { DataGrid } from '@mui/x-data-grid'
import {
  useState,
} from 'react'
import axios from 'axios'
import { MAX_MOBILE_DIMENSIONS } from '../../app/constants'
import NextButton from '../../components/NextButton'
import { IServiceCenterInfo } from '../../app/types'

const columns = [
  { field: 'code', headerName: 'Code', width: 200 },
  { field: 'name', headerName: 'Name', width: 350 },
  { field: 'state', headerName: 'State', width: 50 },
  { field: 'resource_name', headerName: 'Resource Name', width: 400 },
  { field: 'type', headerName: 'Type', width: 200 },
]

const ClinicInfo = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<Array<IServiceCenterInfo>>([])
  const [open, setOpen] = useState(true)
  const [password, setPassword] = useState('')
  const [authorized, setAuthorized] = useState(true)

  const handleClose = () => {
    setLoading(true)
    getData()
    setOpen(false)
  }

  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-service-centers-info`,
        { headers: { Authorization: password } },
      )
      setData(response.data)
      setLoading(false)
    } catch (error: any) {
      if (error.response.status === 401) {
        setAuthorized(false)
      }
    }
  }

  const passwordInput = (e: any) => {
    setPassword(e.target.value)
  }

  if (open) {
    return (
      <StyledModal
        aria-describedby="parent-modal-description"
        aria-labelledby="parent-modal-title"
        onClose={handleClose}
        open={open}
      >
        <Box sx={{ ...style, width: 400 }}>
          <Typography
            sx={{
              fontSize: '20px',
              marginBottom: '15px',
            }}
          >
            Enter admin password

          </Typography>
          <TextField onChange={passwordInput} />
          <NextButton
            onClick={handleClose}
            secondary
            text="Log In"
          />
        </Box>
      </StyledModal>
    )
  }
  if (authorized) {
    return isLoading ? (
      <LoadingBox
        size={50}
      />
    ) : (
      <StyledBox
        columns={columns}
        rows={data}
      />
    )
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography
        sx={{
          fontSize: '20px',
          marginBottom: '15px',
        }}
      >
        Unauthorized
      </Typography>
    </Box>
  )
}

const StyledBox = styled(DataGrid)`
  width: 80%;
  height: 85%;
  position: absolute;
  margin-left: 10%;
  @media (max-width: ${MAX_MOBILE_DIMENSIONS}px) {
    width: 100%;
    margin-left: 0%;
    height: 90%;
  }
`
const StyledModal = styled(Modal)`
  display: grid;
`

const LoadingBox = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`

const style = {
  position: 'absolute' as 'absolute',
  display: 'grid',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

export default ClinicInfo
