import { Outlet, createBrowserRouter } from 'react-router-dom'
import Form from '../views/Form'
import ContactForm from '../views/ContactForm'
import ClinicFinder from '../views/ClinicFinder'
import AppointmentSelector from '../views/AppointmentSelector'
import AppointmentConfirmation from '../views/AppointmentConfirmation'
import NotFound from '../views/NotFound'
import AppointmentDetails from '../views/AppointmentDetails'
import ClinicInfo from '../views/ClinicInfo'
import IdentifyPatient from '../views/IdentifyPatient'
import ScheduledAppointmentInformation from '../views/ScheduledAppointmentInformation'
import AppointmentCancelled from '../views/AppointmentCancelled'
import RescheduleConfirmation from '../views/RescheduleConfirmation'
import Header from '../components/Header'

const LayoutComponent = () => (
  <>
    <Header />
    <Outlet />
  </>
)

const NotFoundRoot = () => (
  <>
    <Header />
    <NotFound />
  </>
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutComponent />,
    errorElement: <NotFoundRoot />,
    children: [
      {
        path: '/',
        element: <Form />,
        errorElement: <NotFound />,
      },
      {
        path: '/contact-form',
        element: <ContactForm />,
        errorElement: <NotFound />,
      },
      {
        path: '/find-clinic',
        element: <ClinicFinder />,
        errorElement: <NotFound />,
      },
      {
        path: '/select-appointment',
        element: <AppointmentSelector />,
        errorElement: <NotFound />,
      },
      {
        path: '/details-appointment',
        element: <AppointmentDetails />,
        errorElement: <NotFound />,
      },
      {
        path: '/confirm-appointment',
        element: <AppointmentConfirmation />,
        errorElement: <NotFound />,
      },
      {
        path: '/clinic-info',
        element: <ClinicInfo />,
        errorElement: <NotFound />,
      },
      {
        path: '/reschedule',
        element: <IdentifyPatient />,
        errorElement: <NotFound />,
      },
      {
        path: '/scheduled-appointment-information',
        element: <ScheduledAppointmentInformation />,
        errorElement: <NotFound />,
      },
      {
        path: '/appointment-cancelled',
        element: <AppointmentCancelled />,
        errorElement: <NotFound />,
      },
      {
        path: '/confirm-reschedule',
        element: <RescheduleConfirmation />,
        errorElement: <NotFound />,
      },
    ],
  },
])

export default router
