import axios from 'axios'

const PostScheduleAppointment = (data: any) => async () => {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/schedule-appointment`

  const response = await axios.post(URL, data)

  return response.data.patientNumber
}
export default PostScheduleAppointment
