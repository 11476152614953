import { Button, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import { PRIMARY_COLOR_LIGHT, SECONDARY_COLOR_LIGHT } from '../app/constants'
import { INextButton } from '../app/types'

const StyledButton = styled(Button)`
  border-radius: 24px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  width: 100%;
  max-width: 350px;
  margin: 15px auto 0;
`

const StyledCircularProgress = styled(CircularProgress)`
  color: inherit;
  margin-right: 10px;
`

const NextButton = ({
  text,
  isDisabled = false,
  isLoading = false,
  onClick = () => {},
  secondary = false,
}: INextButton) => {
  const styleDisabled = {
    backgroundColor: secondary ? SECONDARY_COLOR_LIGHT : PRIMARY_COLOR_LIGHT,
  }

  return (
    <StyledButton
      color={secondary ? 'secondary' : 'primary'}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      style={isDisabled ? styleDisabled : undefined}
      type="submit"
      variant="contained"
    >
      {isLoading ? <StyledCircularProgress size={20} /> : undefined}
      {isLoading ? 'Loading...' : text}
    </StyledButton>
  )
}

export default NextButton
