import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import GppGoodIcon from '@mui/icons-material/GppGood'
import { SUB_COLOR, SUB_COLOR_LIGHT } from '../app/constants'

const StyledItem = styled(Grid)`
  text-align: center;
  padding: 10px 20px;
`

const StyledInfo = styled(Grid)`
  padding-top: 50px;
  justify-content: center;
  padding-bottom: 30px;
`

const StyledInfoMessage = styled(Typography)`
  color: ${SUB_COLOR};
  font-size: 14px;
`

const StyledIcon = styled(GppGoodIcon)`
  font-size: 30px;
  color: ${SUB_COLOR_LIGHT};
`

const ViewContainer = (props: { children: React.ReactNode }) => {
  const { children } = props

  return (
    <Grid container>
      <Grid
        desktop={3}
        item
        mobile={0}
      />
      <StyledItem
        desktop={6}
        item
        mobile={12}
      >
        {children}
        <StyledInfo container>
          <Grid item>
            <StyledIcon />
            <StyledInfoMessage>
              Your personal information is secure and will never be shared with anyone.
            </StyledInfoMessage>
          </Grid>
        </StyledInfo>
      </StyledItem>
      <Grid
        desktop={3}
        item
        mobile={0}
      />
    </Grid>
  )
}
export default ViewContainer
