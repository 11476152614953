import { LinearProgress } from '@mui/material'
import { IProgress } from '../app/types'
import { PROGRESS_BAR_TRAIL_COLOR, INFO_COLOR } from '../app/constants'

const ProgressBar = ({
  progress,
}: IProgress) => (
  <LinearProgress
    sx={{
      margin: '20px 0',
      backgroundColor: PROGRESS_BAR_TRAIL_COLOR,
      '& .MuiLinearProgress-bar': {
        backgroundColor: INFO_COLOR,
      },
    }}
    value={progress * 25}
    variant="determinate"
  />
)

export default ProgressBar
