import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import AppBar from '../../components/AppBar'
import MapComponent, { NextButtonContext } from './map'
import ViewContainer from '../../components/ViewContainer'

const ClinicFinder = () => {
  const reschedule: boolean = useLocation().state?.reschedule ?? false

  const serviceCenterID = useSelector((state: any) => state.appointmentInfo.ServiceCenterID)
  const addressString: string | undefined = `${useSelector((state: any) => state.zipCode.zip)}`
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)
  const navigate = useNavigate()

  const handleOnClickBack = (event: any) => {
    event.preventDefault()

    navigate(-1)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!serviceCenterID) return
    navigate('/select-appointment', { state: { reschedule } })
  }

  return (
    <ViewContainer>
      <AppBar
        description="Select your preferred clinic location for the free evaluation. Start by
        entering your zip code and then choose from the locations listed within your area."
        progress={2}
        title="Select a Clinic"
      />
      <Box>
        <NextButtonContext.Provider value={setIsNextButtonDisabled}>
          <MapComponent
            addressString={addressString}
            handleOnClickBack={handleOnClickBack}
            handleSubmit={handleSubmit}
            isNextButtonDisabled={isNextButtonDisabled}
            serviceCenterId={reschedule ? serviceCenterID : undefined}
          />
        </NextButtonContext.Provider>
      </Box>
    </ViewContainer>
  )
}

export default ClinicFinder
