import { useSelector } from 'react-redux'
import { IFormItem, FormInputProps, IStates } from '../../app/types'
import FormInputText from '../../components/FormInputText'
import FormSection from '../../components/FormSection'
import FormInputDropdown from '../../components/FormInputDropdown'
import { STATE_CENTER_OPTIONS, STATE_OPTIONS } from '../../app/constants'

const ContactForm = ({
  control,
  errors,
  required,
  stateCode,
}: FormInputProps) => {
  const stateOptions = useSelector(
    (state: any) => state.serviceCenterByState?.serviceCenterByState,
  )

  const stateOptionsCenter: {
    [key: string]: { value: string; label: string }[];
  } = stateOptions.reduce((acc: any, { value, centers }: IStates) => {
    const centerArray = centers.map((center) => ({
      value: center,
      label: center,
    }))
    acc[value] = centerArray
    return acc
  }, {})

  const contactDetails: IFormItem[] = [
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          name="stateCode"
          options={stateOptions.length > 0 ? stateOptions : STATE_OPTIONS}
          placeholder="State"
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          isDisabled={!stateCode}
          name="clinicLocation"
          options={
            (Object.keys(stateOptionsCenter).length > 0
              ? stateOptionsCenter[stateCode] : STATE_CENTER_OPTIONS[stateCode]) ?? []
          }
          placeholder="Clinic Location"
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          name="fullName"
          placeholder="Full Name"
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          helperText="Example: myemail@example.com"
          name="email"
          placeholder="Email Address"
          required={required}
          type="email"
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          helperText="Example: 5551234567"
          name="phoneNumber"
          placeholder="Phone Number"
          required={required}
          type="tel"
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          isMultiline
          name="message"
          placeholder="Message"
        />
      ),
    },
  ]

  return (
    <FormSection
      data={contactDetails}
      title="Contact Information"
    />
  )
}

export default ContactForm
