import { Box } from '@mui/material'
import { IFormItem, FormInputProps } from '../../app/types'
import FormInputText from '../../components/FormInputText'
import FormSection from '../../components/FormSection'
import {
  SUBS,
  MAX_LENGTHS,
} from '../../app/constants'
import FormInputDate from '../../components/FormInputDate'
import DateOfBirthHelp from '../../components/DateOfBirthHelp'

const DescriptionPatientInformation = () => (
  <Box component="span">
    Please provide the following information so we can find your existing appointment.
  </Box>
)

const PatientInfoForm = ({
  control,
  errors,
  required,
}: FormInputProps) => {
  const identifyPatient: IFormItem[] = [
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.FIRST_NAME}
          name="patientFirstName"
          placeholder="Patient First Name"
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.LAST_NAME}
          name="patientLastName"
          placeholder="Patient Last Name"
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDate
          control={control}
          errors={errors}
          helperText={<DateOfBirthHelp />}
          isLessThan20MonthsOld
          name="dateOfBirth"
          placeholder="Date of Birth"
          required={required}
        />
      ),
    },
  ]

  return (
    <FormSection
      data={identifyPatient}
      description={<DescriptionPatientInformation />}
      sub={SUBS.required}
      title="Patient Information"
    />
  )
}

export default PatientInfoForm
