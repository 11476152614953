import { Typography, Link } from '@mui/material'
import { CONTACT_NUMBER, SUB_COLOR_LIGHT } from '../app/constants'

const ContactNumber = () => (
  <Typography
    style={{
      fontSize: '14px',
      paddingTop: '15px',
      color: SUB_COLOR_LIGHT,
    }}
  >
    Please call
    {' '}
    <Link
      href={`tel:${CONTACT_NUMBER.value}`}
      style={{ whiteSpace: 'nowrap' }}
    >
      {CONTACT_NUMBER.description}
    </Link>
    {' '}
    if you have any difficulties scheduling your evaluation.
  </Typography>
)

export default ContactNumber
