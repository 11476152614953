import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { SUB_COLOR } from './constants'

export const ButtonWrapper = styled.div`
  margin-bottom: 10%;
  width: 100%;
`

export const VerticalSpacer = styled.div`
  height: 4rem;
`

export const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`

export const StyledDescription = styled(Typography)`
  color: ${SUB_COLOR};
  font-size: 14px;
  font-weight: 600;
`
