import * as Sentry from '@sentry/browser'
import store from '../app/store'

const sendSentryExcetion = (message: string) => {
  const storeData = JSON.parse(JSON.stringify(store.getState()))
  delete storeData?.formInfo?.PersonDetails
  delete storeData?.formInfo?.Guardian?.Guardian1Relation
  delete storeData?.formInfo?.Guardian?.Guardian2Relation
  const additionalInfo = {
    store: JSON.stringify(storeData),
  }

  Sentry.captureException(message, {
    extra: additionalInfo,
  })
}

export default sendSentryExcetion
