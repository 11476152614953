import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import NextButton from '../../components/NextButton'
import ViewContainer from '../../components/ViewContainer'
import people from '../../assets/images/zion-smith.jpg'
import { DISPLAY_FONT_FAMILY } from '../../app/constants'
import {
  CenteredColumn, StyledDescription, VerticalSpacer, ButtonWrapper,
} from '../../app/styles'

const RescheduleConfirmation = () => {
  const navigate = useNavigate()
  return (
    <ViewContainer>
      <CenteredColumn>
        <Box
          alt="people"
          component="img"
          src={people}
          width="75%"
        />

        <Typography
          sx={{
            fontFamily: DISPLAY_FONT_FAMILY,
            fontWeight: 600,
            padding: '10px 0',
          }}
          variant="h5"
        >
          Appointment Rescheduled
        </Typography>

        <StyledDescription>
          This appointment has been successfully rescheduled.
        </StyledDescription>

        <VerticalSpacer />

        <ButtonWrapper>
          <NextButton
            onClick={() => navigate('/')}
            secondary
            text="Back to Home"
          />
        </ButtonWrapper>
      </CenteredColumn>
    </ViewContainer>
  )
}

export default RescheduleConfirmation
