const ClipboardIcon = () => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 42 48"
    width="42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.125 7.5C3.125 7.10218 3.26987 6.72064 3.52773 6.43934C3.78559 6.15804 4.13533 6 4.5 6H8.625V7.5H5.875C5.51033 7.5 5.16059 7.65804 4.90273 7.93934C4.64487 8.22064 4.5 8.60218 4.5 9V42C4.5 42.3978 4.64487 42.7794 4.90273 43.0607C5.16059 43.342 5.51033 43.5 5.875 43.5H14.125V40.5H7.25V10.5H29.25V16.5H32V9C32 8.60218 31.8551 8.22064 31.5973 7.93934C31.3394 7.65804 30.9897 7.5 30.625 7.5H27.875V6H32C32.3647 6 32.7144 6.15804 32.9723 6.43934C33.2301 6.72064 33.375 7.10218 33.375 7.5V18H36.125V7.5C36.125 6.30653 35.6904 5.16193 34.9168 4.31802C34.1432 3.47411 33.094 3 32 3H30.625C30.625 2.20435 30.3353 1.44129 29.8195 0.87868C29.3038 0.316071 28.6043 0 27.875 0L8.625 0C7.89565 0 7.19618 0.316071 6.68046 0.87868C6.16473 1.44129 5.875 2.20435 5.875 3H4.5C3.40598 3 2.35677 3.47411 1.58318 4.31802C0.809597 5.16193 0.375 6.30653 0.375 7.5V46.5C0.375 46.8978 0.519866 47.2794 0.777728 47.5607C1.03559 47.842 1.38533 48 1.75 48H15.5V45H3.125V7.5ZM11.375 3H25.125V7.5H11.375V3Z"
      fill="#147F6E"
    />
    <path
      d="M29.25 21C26.8025 21 24.4099 21.7918 22.3748 23.2752C20.3398 24.7586 18.7536 26.867 17.817 29.3338C16.8804 31.8006 16.6353 34.515 17.1128 37.1337C17.5903 39.7525 18.7689 42.1579 20.4996 44.0459C22.2302 45.9339 24.4352 47.2197 26.8358 47.7406C29.2363 48.2615 31.7245 47.9942 33.9857 46.9724C36.2469 45.9506 38.1797 44.2203 39.5394 42.0002C40.8992 39.7801 41.625 37.17 41.625 34.5C41.625 30.9196 40.3212 27.4858 38.0004 24.9541C35.6797 22.4223 32.5321 21 29.25 21ZM29.25 45C27.3464 45 25.4855 44.3842 23.9026 43.2304C22.3198 42.0767 21.0862 40.4368 20.3577 38.5182C19.6292 36.5996 19.4386 34.4884 19.8099 32.4516C20.1813 30.4148 21.098 28.5438 22.4441 27.0754C23.7902 25.6069 25.5052 24.6069 27.3723 24.2018C29.2393 23.7966 31.1746 24.0045 32.9333 24.7993C34.6921 25.594 36.1953 26.9398 37.2529 28.6665C38.3105 30.3932 38.875 32.4233 38.875 34.5C38.875 37.2848 37.8609 39.9555 36.0559 41.9246C34.2509 43.8938 31.8027 45 29.25 45Z"
      fill="#147F6E"
    />
    <path
      d="M30.625 28.5H27.875V34.5C27.874 34.6974 27.9087 34.8931 27.9771 35.0759C28.0455 35.2586 28.1463 35.4248 28.2738 35.565L32.3988 40.065L34.3375 37.95L30.625 33.885V28.5Z"
      fill="#147F6E"
    />
  </svg>
)

export default ClipboardIcon
