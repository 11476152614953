import axios from 'axios'

async function getAppointmentDuration(language: string) {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/get-appointment-duration?language=${language}`

  try {
    const resp = await axios.get(URL)
    return resp.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default getAppointmentDuration
