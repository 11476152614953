import { Box, Link } from '@mui/material'
import { CONTACT_NUMBER } from '../app/constants'

const DateOfBirthHelp = () => (
  <>
    <Box
      component="span"
      style={{ fontSize: 12, opacity: 0.6 }}
    >
      If your baby is older than 20 months, please call us at
      {' '}
    </Box>
    <Link
      href={`tel:${CONTACT_NUMBER.value}`}
      style={{ whiteSpace: 'nowrap' }}
    >
      {CONTACT_NUMBER.description}
    </Link>
  </>
)

export default DateOfBirthHelp
