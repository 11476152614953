const LocationIcon = () => (
  <svg
    fill="none"
    height="42"
    viewBox="0 0 41 42"
    width="41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 37.5C20.1766 37.5 19.8517 37.3815 19.5887 37.143C19.2484 36.834 11.2328 29.493 7.88875 21.486C7.57234 20.73 7.89156 19.842 8.60172 19.506C9.30906 19.17 10.1416 19.509 10.458 20.2665C12.9034 26.124 18.3006 31.815 20.4761 33.9615C24.3152 29.9865 31.75 21.1035 31.75 15.345C31.75 8.538 26.703 3 20.5 3C14.297 3 9.25 8.538 9.25 15.345C9.25 16.173 8.62 16.845 7.84375 16.845C7.0675 16.845 6.4375 16.173 6.4375 15.345C6.4375 6.8835 12.7459 0 20.5 0C28.2541 0 34.5625 6.8835 34.5625 15.345C34.5625 24.213 21.985 36.5835 21.4492 37.107C21.1806 37.368 20.8403 37.5 20.5 37.5Z"
      fill="#147F6E"
    />
    <path
      d="M20.5 21C17.3978 21 14.875 18.309 14.875 15C14.875 11.691 17.3978 9 20.5 9C23.6022 9 26.125 11.691 26.125 15C26.125 18.309 23.6022 21 20.5 21ZM20.5 12C18.9489 12 17.6875 13.3455 17.6875 15C17.6875 16.6545 18.9489 18 20.5 18C22.0511 18 23.3125 16.6545 23.3125 15C23.3125 13.3455 22.0511 12 20.5 12Z"
      fill="#147F6E"
    />
    <path
      d="M38.7813 41.9991H2.21876C1.71813 41.9991 1.25407 41.7156 1.00235 41.2521C0.750631 40.7886 0.749225 40.2201 0.998131 39.7551L6.62313 29.2551C7.00844 28.5366 7.86766 28.2846 8.54126 28.6971C9.21485 29.1081 9.44969 30.0246 9.06438 30.7431L4.64172 38.9991H36.3569L31.9342 30.7431C31.5489 30.0246 31.7838 29.1081 32.4573 28.6971C33.1309 28.2831 33.9902 28.5366 34.3755 29.2551L40.0005 39.7551C40.2494 40.2201 40.248 40.7886 39.9963 41.2521C39.7459 41.7156 39.2819 41.9991 38.7813 41.9991Z"
      fill="#147F6E"
    />
  </svg>
)

export default LocationIcon
