import Ajv from 'ajv'
import addFormats from 'ajv-formats'

const ajv = new Ajv()
addFormats(ajv)

const checkRequest = (data: any) => {
  const personDetails = {
    type: 'object',
    properties: {
      Name: { type: 'object' },
      DOB: { type: 'string', pattern: '^.+$' },
      Gender: { type: 'object' },
    },
    required: ['Name', 'DOB', 'Gender'],
    additionalProperties: true,
  }

  const guardian = {
    type: 'object',
    properties: {
      Guardian1FirstName: { type: 'string', pattern: '^.+$' },
      Guardian1LastName: { type: 'string', pattern: '^.+$' },
      Guardian1Relation: { type: 'object' },
      Guardian1Phone: { type: 'object' },
      Guardian1Email: { type: 'string', pattern: '^.+$' },
    },
    required: ['Guardian1FirstName', 'Guardian1LastName',
      'Guardian1Relation', 'Guardian1Phone', 'Guardian1Email'],
    additionalProperties: true,
  }

  const appointment = {
    type: 'object',
    properties: {
      ServiceCenterID: { type: 'integer' },
      AppointmentDateTime: { type: 'string', pattern: '^.+$' },
    },
    required: ['ServiceCenterID', 'AppointmentDateTime'],
    additionalProperties: true,
  }

  const campaignEntry = {
    type: 'object',
    properties: {
      utm_campaign: { type: 'string' },
    },
    additionalProperties: true,
  }

  const scheduleSchema = {
    type: 'object',
    properties: {
      PersonDetails: personDetails,
      Guardian: guardian,
      Appointment: appointment,
      ZipCode: { type: 'string', pattern: '^.+$' },
      CampaignEntryURLData: campaignEntry,
    },
    required: ['PersonDetails', 'Guardian', 'Appointment'],
    additionalProperties: false,
  }
  const valid = ajv.validate(scheduleSchema, data)
  return valid
}

export default checkRequest
