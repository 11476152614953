import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { DISPLAY_FONT_FAMILY, SUB_COLOR } from '../app/constants'
import { IAppBar } from '../app/types'
import ProgressBar from './ProgressBar'

const StyledDescription = styled(Typography)`
  color: ${SUB_COLOR};
  font-size: 14px;
  text-wrap: balance;
`

const StyledContainer = styled(Grid)`
  padding-bottom: 30px;
`

const AppBar = ({
  title,
  description,
  progress,
}: IAppBar) => (
  <StyledContainer container>
    <Grid
      item
      width="100%"
    >
      {progress !== 0 && (
        <>
          <ProgressBar progress={progress} />
          <Typography
            sx={{
              color: SUB_COLOR,
              fontSize: '14px',
            }}
          >
            Step
            {' '}
            {progress}
            {' '}
            of 4
          </Typography>
        </>
      )}
      <Typography
        sx={{
          fontFamily: DISPLAY_FONT_FAMILY,
          fontWeight: 600,
          padding: '10px 0',
        }}
        variant="h5"
      >
        {title}
      </Typography>
      <StyledDescription>
        {description}
      </StyledDescription>
    </Grid>
  </StyledContainer>
)

export default AppBar
